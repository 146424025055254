import { TextField } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import { fetch_wrapper } from '../../utils/fetch_wrapper';
import * as stepActions from '../../store/step';
import * as userFormDataActions from '../../store/userFormData';
import { force_minimum_load } from '../../utils/minimum_loading';
import LoadingSpinner from '../../components/LoadingSpinnerContainer/loadingSpinner';
import LoadingSpinnerContainer from '../../components/LoadingSpinnerContainer';

const InfoFormPage = ({ validSession, usingMobile }: { validSession: boolean; usingMobile: boolean }) => {
    const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [updateSent, setUpdateSent] = useState<boolean>(false);
    const [customerName, setCustomerName] = useState<string>('');
    const [nameFocused, setNameFocused] = useState<boolean>(false);
    const [addressValue, setAddressValue] = useState<string>('');
    const [addressFocused, setAddressFocused] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(stepActions.setCurrentStep(1));
        dispatch(stepActions.setMaxStep(1));
    }, []);

    const handleSubmit = async () => {
        setUpdateSent(true);
        const waitPromise = force_minimum_load();
        fetch_wrapper(`/update-user-info`, {
            method: 'POST',
            body: JSON.stringify({
                name: customerName,
                address: place!['formatted_address'],
            }),
            credentials: 'include',
        });
        await waitPromise;
        dispatch(stepActions.setMaxStep(2));
        dispatch(userFormDataActions.setName(customerName));
        dispatch(userFormDataActions.setAddress(place!['formatted_address']));
        navigate('/electric');
    };

    class GooglePlacesAutocomplete {
        private autocomplete: google.maps.places.Autocomplete;

        constructor(inputElement: HTMLInputElement) {
            const options = {
                componentRestrictions: { country: ['us', 'ca'] }, // Restrict to US and Canada
                types: ['address'], // Restrict to addresses only
            };
            this.autocomplete = new google.maps.places.Autocomplete(inputElement, options);
            this.setupListeners();
        }

        private setupListeners() {
            this.autocomplete.addListener('place_changed', () => {
                const place = this.autocomplete.getPlace();
                setAddressValue(place['formatted_address']!);
                setPlace(place);
            });
        }
    }

    useEffect(() => {
        const inputElement = document.getElementById('autocompleteInput') as HTMLInputElement;
        if (inputElement && validSession) {
            new GooglePlacesAutocomplete(inputElement);
        }
    }, [validSession]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && place && customerName && !updateSent) {
                handleSubmit();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [place, customerName, updateSent]);

    return (
        <>
            {validSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: usingMobile ? '18vh' : '',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: usingMobile ? '1.75rem' : '1.875rem',
                                lineHeight: '36px',
                                height: usingMobile ? '3.5vh' : '',
                            }}
                        >
                            ADDRESS INFO
                        </div>

                        <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>

                        <div
                            style={{
                                fontFamily: 'AmericanTypewriter',
                                fontWeight: '400',
                                fontSize: '1.125rem',
                                lineHeight: '26px',
                                height: usingMobile ? '11.5vh' : '',
                            }}
                        >
                            Provide your address information and we’ll quickly let you know if you’re optimizing your
                            home energy expenditures.
                        </div>
                    </div>
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: usingMobile ? 'space-between' : 'flex-start',
                            alignItems: usingMobile ? 'center' : 'flex-start',
                            width: '100%',
                            height: usingMobile ? '62.5vh' : '',
                            marginTop: usingMobile ? '6vh' : '5vh',
                            marginBottom: '0px',
                        }}
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                height: usingMobile ? '20vh' : '',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '0.875rem',
                                        fontWeight: '500',
                                        marginBottom: '1vh',
                                        color: '#495057',
                                    }}
                                >
                                    Name
                                </div>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Complete Name"
                                    className="basic_input"
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    onFocus={() => setNameFocused(true)}
                                    onBlur={() => setNameFocused(false)}
                                    style={{
                                        height: '6vh',
                                        maxHeight: '46px',
                                        width: '100%',
                                        border: '1px solid',
                                        borderRadius: '4px',
                                        padding: '13px',
                                        fontSize: '1rem',
                                        lineHeight: '22px',
                                        fontFamily: 'Roboto',
                                        fontWeight: '400',
                                        borderColor: nameFocused || customerName ? '#6C757D' : '#CED4DA',
                                        color: '#212529',
                                        transition: 'border-color 0.3s',
                                    }}
                                />
                            </div>

                            <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '0.875rem',
                                        fontWeight: '500',
                                        marginBottom: '1vh',
                                        color: '#495057',
                                    }}
                                >
                                    Address
                                </div>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Address"
                                    className="basic_input"
                                    id="autocompleteInput"
                                    value={addressValue}
                                    onChange={(e) => setAddressValue(e.target.value)}
                                    onFocus={() => setAddressFocused(true)}
                                    onBlur={() => setAddressFocused(false)}
                                    style={{
                                        height: '6vh',
                                        maxHeight: '46px',
                                        width: '100%',
                                        border: '1px solid',
                                        borderRadius: '4px',
                                        padding: '13px',
                                        fontSize: '1rem',
                                        lineHeight: '22px',
                                        fontFamily: 'Roboto',
                                        fontWeight: '400',
                                        borderColor: place || addressFocused || addressValue ? '#6C757D' : '#CED4DA',
                                        color: '#212529',
                                        transition: 'border-color 0.3s',
                                    }}
                                />
                            </div>
                        </div>

                        {!usingMobile && <div style={{ height: '4vh' }}></div>}

                        <button
                            type="submit"
                            style={{
                                fontFamily: 'Raleway',
                                fontSize: '1.25rem',
                                lineHeight: '22px',
                                fontWeight: '500',
                                backgroundColor:
                                    place && customerName ? (updateSent ? '#CED4DA' : '#000000') : '#CED4DA',
                                color: '#FFFFFF',
                                borderRadius: '4px',
                                cursor: !place || !customerName ? 'default' : updateSent ? 'wait' : 'pointer',
                                height: usingMobile ? '7.5vh' : '8vh',
                                width: usingMobile ? '98%' : '35%',
                                marginBottom: usingMobile ? '4vh' : '',
                                maxHeight: usingMobile ? '' : '50px',
                                maxWidth: usingMobile ? '' : '130px',
                            }}
                            disabled={!place || !customerName || updateSent}
                        >
                            {updateSent ? '' : 'SUBMIT'}

                            <LoadingSpinner
                                active={updateSent}
                                usingMobile={usingMobile}
                                large={false}
                            ></LoadingSpinner>
                        </button>
                    </form>
                    {/* {!usingMobile && (
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <LoadingSpinnerContainer
                                loadingText="We received your information and are verifying your data"
                                active={updateSent}
                                usingMobile={usingMobile}
                            ></LoadingSpinnerContainer>
                        </div>
                    )} */}
                </>
            )}
            {!validSession && <div>Please Log In Before Adding User Data</div>}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
}))(InfoFormPage);

import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetch_wrapper } from '../../utils/fetch_wrapper';
import * as stepActions from '../../store/step';
import { useNavigate } from 'react-router-dom';
import * as userFormDataActions from '../../store/userFormData';
import LoadingSpinnerAndText from '../../components/LoadingSpinnerContainer';
import { force_minimum_load } from '../../utils/minimum_loading';
import LoadingSpinner from '../../components/LoadingSpinnerContainer/loadingSpinner';
import LoadingSpinnerContainer from '../../components/LoadingSpinnerContainer';
import LoadingText from '../../components/LoadingSpinnerContainer/loadingText';

type Utility = {
    full_name: string;
    id: string;
    is_available: boolean;
    logo_url: string;
    slug: string;
    website_url: string;
    type: string;
};

const createUpdateUtilCreds = async (userData: { username: string; password: string; utility_id: string }) => {
    const response = await fetch_wrapper(`/create-update-util-creds`, {
        method: 'POST',
        body: JSON.stringify(userData),
        credentials: 'include',
    });
    return response.status;
};

const scrapeHistoricalData = (utilityId: string) => {
    fetch_wrapper(`/scrape-historical-data`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ utility_id: utilityId }),
    });
};

const GasCredsPage = ({
    validSession,
    usingMobile,
    gasUtilities,
}: {
    validSession: boolean;
    usingMobile: boolean;
    gasUtilities: Utility[];
}) => {
    const [gasUsername, setGasUsername] = useState('');
    const [gasPassword, setGasPassword] = useState('');
    const [gasUtil, setGasUtil] = useState<Utility | null>(null);
    const [gasFetching, setGasFetching] = useState(false);
    const [validGasCreds, setGasValidCreds] = useState<boolean | null>(null);
    const [usernameFocused, setUsernameFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [skipping, setSkipping] = useState(false);
    const [hasPropane, setHasPropane] = useState(false);
    const [noGas, setNoGas] = useState(false);
    const [credentialsAlreadyInUse, setCredentialsAlreadyInUse] = useState(false);
    const [skipFetching, setSkipFetching] = useState<boolean>(false);
    const [unavailableLoading, setUnavailableLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(stepActions.setCurrentStep(3));
        dispatch(stepActions.setMaxStep(3));
    }, []);

    const handleGasSubmit = () => {
        setSubmitted(true);
        if (gasUtil?.is_available) {
            setGasFetching(true);
        } else {
            setUnavailableLoading(true);
        }
        setSkipping(false);
        handleGasCredentials(true);
    };

    const handleSkippingGas = async () => {
        setSkipFetching(true);
        setUnavailableLoading(false);
        if (hasPropane) {
            handleGasCredentials(false);
        } else {
            const waitPromise = force_minimum_load();
            await waitPromise;
            dispatch(stepActions.setMaxStep(4));
            navigate('/confirm');
        }
    };

    const handleGasCredentials = (utilityGasSubmit: boolean) => {
        const waitPromise = force_minimum_load();
        createUpdateUtilCreds({
            username: hasPropane ? '' : gasUsername.trim(),
            password: hasPropane ? '' : gasPassword,
            utility_id: hasPropane ? '0' : gasUtil!.id,
        }).then(async (responseCode) => {
            if (!hasPropane) {
                if (responseCode === 200) {
                    if (gasUtil?.is_available) {
                        fetch_wrapper(`/verify-utility-credentials`, {
                            method: 'POST',
                            body: JSON.stringify({
                                utility_id: gasUtil!.id,
                            }),
                            credentials: 'include',
                        })
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (utilityGasSubmit) {
                                    if (!data.failed_verification) {
                                        setGasValidCreds(true);
                                        // scrapeHistoricalData(gasUtilId);
                                        await waitPromise;
                                        dispatch(userFormDataActions.setGasUtilId(gasUtil!.id));
                                        dispatch(stepActions.setMaxStep(4));
                                        navigate('/confirm');
                                    } else {
                                        await waitPromise;
                                        setSubmitted(false);
                                        setGasFetching(false);
                                        setGasValidCreds(false);
                                    }
                                }
                            });
                    } else {
                        await waitPromise;
                        dispatch(userFormDataActions.setGasUtilId(gasUtil!.id));
                        dispatch(stepActions.setMaxStep(4));
                        navigate('/confirm');
                    }
                } else if (responseCode === 403) {
                    await waitPromise;
                    setSubmitted(false);
                    setCredentialsAlreadyInUse(true);
                    setGasFetching(false);
                    setGasUsername('');
                    setGasPassword('');
                }
            } else {
                await waitPromise;
                dispatch(stepActions.setMaxStep(4));
                navigate('/confirm');
            }
        });
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                if (gasUtil && gasUsername.trim() && gasPassword && !gasFetching) {
                    handleGasSubmit();
                } else if (skipping && (hasPropane || noGas)) {
                    handleSkippingGas();
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [gasUtil, gasUsername, gasPassword, gasFetching, skipping, hasPropane, noGas]);

    // useEffect(() => {
    //     fetch_wrapper(`/get-user-util-status`, {
    //         method: 'GET',
    //         credentials: 'include',
    //     })
    //         .then((response) => response.json())
    //         .then((json) => JSON.parse(json))
    //         .then((data) => {
    //             setGasValidCreds(true);
    //         })
    //         .catch((error) => console.log('error', error));
    // }, []);

    return (
        <>
            {validSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: usingMobile ? '15vh' : '',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: usingMobile ? '1.75rem' : '1.875rem',
                                lineHeight: '36px',
                                height: usingMobile ? '3.5vh' : '',
                            }}
                        >
                            GAS UTILITY
                        </div>

                        <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>

                        <div
                            style={{
                                fontFamily: 'AmericanTypewriter',
                                fontWeight: '400',
                                fontSize: '1.125rem',
                                lineHeight: '26px',
                                height: usingMobile ? '8.5vh' : '',
                            }}
                        >
                            Connect your Gas utility so that we can identify your usage pattern and help you optimize.
                        </div>
                    </div>

                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: usingMobile ? 'space-between' : 'flex-start',
                            alignItems: usingMobile ? 'center' : 'flex-start',
                            width: '100%',
                            height: usingMobile ? '66vh' : '',
                            marginTop: usingMobile ? '6vh' : '5vh',
                            marginBottom: '0px',
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleGasSubmit();
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                height: usingMobile ? '42vh' : '',
                                justifyContent: 'space-between',
                                opacity: submitted ? '0.7' : '1',
                                pointerEvents: submitted ? 'none' : 'auto',
                            }}
                            onClick={(e) => {
                                if (submitted) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: usingMobile ? '6vh' : '',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '0.875rem',
                                        fontWeight: '500',
                                        color: '#495057',
                                    }}
                                >
                                    Gas Utility
                                </div>
                                <select
                                    style={{
                                        fontFamily: 'Roboto',
                                        fontWeight: '400',
                                        fontSize: usingMobile ? '0.875rem' : '1rem',
                                        color: '#212529',
                                        borderRadius: '5px',
                                        borderColor: '#6C757D',
                                        height: '48px',
                                        padding: '12px',
                                        appearance: 'none',
                                        backgroundImage: `url(/caret-down.svg)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'calc(100% - 10px) center', // Adjust left positioning
                                        backgroundSize: '24px', // Size of the caret
                                        paddingRight: '40px',
                                        paddingLeft: '12px',
                                    }}
                                    value={gasUtil ? JSON.stringify(gasUtil) : undefined}
                                    onChange={(e) => setGasUtil(JSON.parse(e.target.value))}
                                >
                                    <option value="" hidden>
                                        Select an option
                                    </option>
                                    {gasUtilities &&
                                        gasUtilities.map((value: Utility, key: number) => {
                                            return (
                                                <option
                                                    style={{
                                                        fontFamily: 'Roboto',
                                                        fontWeight: '400',
                                                        fontSize: '1rem',
                                                    }}
                                                    value={JSON.stringify(value)}
                                                    key={key}
                                                >
                                                    {value.full_name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: usingMobile ? '20vh' : '',
                                    justifyContent: 'space-between',
                                    opacity: gasUtil ? '1' : '0.5',
                                    pointerEvents: gasUtil ? 'unset' : 'none',
                                }}
                                onClick={(e) => {
                                    if (!gasUtil) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: 'Raleway',
                                            fontSize: '0.875rem',
                                            fontWeight: '500',
                                            marginBottom: '1vh',
                                            color: '#495057',
                                        }}
                                    >
                                        Username
                                    </div>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Utility Username"
                                        className="basic_input"
                                        value={gasUsername}
                                        onChange={(e) => setGasUsername(e.target.value)}
                                        onFocus={() => setUsernameFocused(true)}
                                        onBlur={() => setUsernameFocused(false)}
                                        style={{
                                            height: '6vh',
                                            maxHeight: '46px',
                                            width: '100%',
                                            border: '1px solid',
                                            borderRadius: '4px',
                                            padding: '13px',
                                            fontSize: '1rem',
                                            lineHeight: '22px',
                                            fontFamily: 'Roboto',
                                            fontWeight: '400',
                                            borderColor: usernameFocused || gasUsername ? '#6C757D' : '#CED4DA',
                                            color: '#212529',
                                            transition: 'border-color 0.3s',
                                        }}
                                    ></input>
                                </div>

                                <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: 'Raleway',
                                            fontSize: '0.875rem',
                                            fontWeight: '500',
                                            marginBottom: '1vh',
                                            color: '#495057',
                                        }}
                                    >
                                        Password
                                    </div>

                                    <input
                                        autoComplete="off"
                                        type="password"
                                        placeholder="Utility Password"
                                        className="basic_input"
                                        value={gasPassword}
                                        onChange={(e) => setGasPassword(e.target.value)}
                                        onFocus={() => setPasswordFocused(true)}
                                        onBlur={() => setPasswordFocused(false)}
                                        style={{
                                            height: '6vh',
                                            maxHeight: '46px',
                                            width: '100%',
                                            border: '1px solid',
                                            borderRadius: '4px',
                                            padding: '13px',
                                            fontSize: '1rem',
                                            lineHeight: '22px',
                                            fontFamily: 'Roboto',
                                            fontWeight: '400',
                                            borderColor: passwordFocused || gasPassword ? '#6C757D' : '#CED4DA',
                                            color: '#212529',
                                            transition: 'border-color 0.3s',
                                        }}
                                    ></input>
                                </div>
                            </div>
                            {usingMobile && (
                                <div
                                    style={{
                                        height: '2vh',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        marginTop: '3vh',
                                    }}
                                >
                                    {!gasFetching && !skipping && (
                                        <>
                                            {!validGasCreds && validGasCreds !== null && !credentialsAlreadyInUse && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontFamily: 'Raleway',
                                                        fontSize: '0.875rem',
                                                        width: '100%',
                                                        height: '2vh',
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    Credentials Invalid
                                                </div>
                                            )}
                                            {credentialsAlreadyInUse && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontFamily: 'Raleway',
                                                        fontSize: '0.875rem',
                                                        width: '100%',
                                                        height: '2vh',
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    Credentials already registered
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                            {usingMobile && <div style={{ height: '7vh' }}></div>}
                        </div>

                        {!usingMobile && <div style={{ height: '4vh' }}></div>}

                        {usingMobile && (
                            <div style={{ width: '80%' }}>
                                <LoadingText
                                    active={gasFetching}
                                    loadingText={'We received your utility credentials and are processing your data'}
                                    usingMobile={usingMobile}
                                ></LoadingText>
                            </div>
                        )}

                        <div
                            style={{
                                width: usingMobile ? '98%' : '100%',
                                display: 'flex',
                                justifyContent: usingMobile ? 'space-between' : 'flex-start',
                            }}
                        >
                            {usingMobile && (
                                <button
                                    type="button"
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '1.25rem',
                                        fontWeight: '500',
                                        lineHeight: '22px',
                                        backgroundColor: '#FFFFFF',
                                        color: gasFetching ? '#ADB5BD' : '#212529',
                                        border: '1px solid',
                                        borderColor: gasFetching ? '#ADB5BD' : '#212529',
                                        borderRadius: '4px',
                                        cursor:
                                            !skipping && !gasFetching
                                                ? 'pointer'
                                                : hasPropane || noGas
                                                ? 'pointer'
                                                : 'default',
                                        width: '47.5%',
                                        height: '7.5vh',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (!skipping && !gasFetching) {
                                            setSkipping(true);
                                        } else if (hasPropane || noGas) {
                                            handleSkippingGas();
                                        }
                                    }}
                                >
                                    {!skipping ? 'SKIP' : 'NEXT'}
                                </button>
                            )}

                            <button
                                type="submit"
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '1.25rem',
                                    fontWeight: '500',
                                    lineHeight: '22px',
                                    backgroundColor:
                                        gasUtil &&
                                        gasUsername.trim() &&
                                        gasPassword &&
                                        !gasFetching &&
                                        !unavailableLoading
                                            ? '#000000'
                                            : '#CED4DA',
                                    color: '#FFFFFF',
                                    borderRadius: '4px',
                                    cursor: !(gasUtil && gasUsername.trim() && gasPassword)
                                        ? 'default'
                                        : gasFetching || unavailableLoading
                                        ? 'wait'
                                        : 'pointer',
                                    width: usingMobile ? '47.5%' : '100%',
                                    height: usingMobile ? '7.5vh' : '8vh',
                                    marginBottom: usingMobile ? '4vh' : '',
                                    maxHeight: usingMobile ? '' : '50px',
                                    maxWidth: usingMobile ? '' : '130px',
                                    opacity: gasUtil ? '1' : '0.5',
                                }}
                                disabled={
                                    !(gasUtil && gasUsername.trim() && gasPassword) || gasFetching || unavailableLoading
                                }
                            >
                                {unavailableLoading || (gasFetching && skipping) || (usingMobile && gasFetching)
                                    ? ''
                                    : 'SUBMIT'}

                                <LoadingSpinner
                                    active={
                                        unavailableLoading || (gasFetching && skipping) || (usingMobile && gasFetching)
                                    }
                                    usingMobile={usingMobile}
                                    large={false}
                                ></LoadingSpinner>
                            </button>

                            {!usingMobile && !gasFetching && !unavailableLoading && !skipping && (
                                <button
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '1.25rem',
                                        fontWeight: '500',
                                        lineHeight: '22px',
                                        backgroundColor: '#FFFFFF',
                                        color: '#212529',
                                        border: '1px solid',
                                        borderColor: '#212529',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        width: '100%',
                                        maxHeight: '50px',
                                        maxWidth: '100px',
                                        marginLeft: '28px',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSkipping(true);
                                    }}
                                >
                                    SKIP
                                </button>
                            )}
                        </div>
                    </form>
                    {!usingMobile && (
                        <>
                            {!unavailableLoading && !gasFetching && !skipping && (
                                <div
                                    style={{
                                        height: '1.5vh',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        marginTop: '2vh',
                                    }}
                                >
                                    <>
                                        {!validGasCreds && validGasCreds !== null && !credentialsAlreadyInUse && (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    fontFamily: 'Raleway',
                                                    fontSize: '1rem',
                                                    width: '100%',
                                                    height: '1.5vh',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                Credentials Invalid
                                            </div>
                                        )}
                                        {credentialsAlreadyInUse && (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    fontFamily: 'Raleway',
                                                    fontSize: '1rem',
                                                    width: '100%',
                                                    height: '1.5vh',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                Credentials already registered
                                            </div>
                                        )}
                                    </>
                                </div>
                            )}
                        </>
                    )}

                    {!usingMobile && gasFetching && !skipping && (
                        <>
                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <LoadingSpinnerContainer
                                    loadingText="We received your utility credentials and are processing your data"
                                    active={gasFetching}
                                    usingMobile={usingMobile}
                                ></LoadingSpinnerContainer>
                            </div>
                        </>
                    )}

                    {!usingMobile && skipping && (
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: '80%',
                                        fontFamily: 'Raleway',
                                        fontSize: '1.875rem',
                                        lineHeight: '26px',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: '#F9136B',
                                    }}
                                >
                                    ARE YOU SURE?
                                </div>

                                <div style={{ height: '2vh' }}></div>

                                <div
                                    style={{
                                        width: '80%',
                                        fontFamily: 'AmericanTypewriter',
                                        fontSize: '1.125rem',
                                        lineHeight: '26px',
                                        fontWeight: '400',
                                        textAlign: 'center',
                                        color: '#343A40',
                                    }}
                                >
                                    We cant be sure on your savings if you have natural gas service and you don't
                                    connect it.
                                </div>

                                <div style={{ height: '2.5vh' }}></div>

                                <div
                                    style={{
                                        width: '80%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => {
                                            setNoGas(false);
                                            setHasPropane(!hasPropane);
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '22px',
                                                width: '22px',
                                                margin: '0px',
                                                border: '1px solid #1C1C1A',
                                                borderRadius: '50%',
                                                backgroundColor: hasPropane ? '#1C1C1A' : '#FFFFFF',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {hasPropane && (
                                                <div
                                                    style={{
                                                        height: '12px',
                                                        width: '12px',
                                                        margin: '0px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#FFFFFF',
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                        <label
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontSize: '0.875rem',
                                                lineHeight: '16px',
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                color: '#1C1C1A',
                                                marginLeft: '12px',
                                            }}
                                        >
                                            I have Propane/Heating Oil
                                        </label>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            position: 'relative',
                                        }}
                                        onClick={() => {
                                            setHasPropane(false);
                                            setNoGas(!noGas);
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '22px',
                                                width: '22px',
                                                margin: '0px',
                                                border: '1px solid #1C1C1A',
                                                borderRadius: '50%',
                                                backgroundColor: noGas ? '#1C1C1A' : '#FFFFFF',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {noGas && (
                                                <div
                                                    style={{
                                                        height: '12px',
                                                        width: '12px',
                                                        margin: '0px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#FFFFFF',
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                        <label
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontSize: '0.875rem',
                                                lineHeight: '16px',
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                color: '#1C1C1A',
                                                marginLeft: '12px',
                                            }}
                                        >
                                            I don’t have gas
                                        </label>
                                    </div>
                                </div>

                                <div style={{ height: '3vh' }}></div>

                                <button
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '1.25rem',
                                        fontWeight: '500',
                                        lineHeight: '22px',
                                        backgroundColor: skipFetching
                                            ? '#CED4DA'
                                            : hasPropane || noGas
                                            ? '#000000'
                                            : '#CED4DA',
                                        color: '#FFFFFF',
                                        borderColor: '#212529',
                                        borderRadius: '4px',
                                        cursor: hasPropane || noGas ? 'pointer' : 'default',
                                        width: '25%',
                                        height: '8vh',
                                        marginBottom: '',
                                        maxHeight: '50px',
                                        maxWidth: '120px',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (!skipping) {
                                            setSkipping(true);
                                        } else if (hasPropane || noGas) {
                                            handleSkippingGas();
                                        }
                                    }}
                                    disabled={(!hasPropane && !noGas) || skipFetching}
                                >
                                    {!skipFetching && 'NEXT'}
                                    {skipFetching && (
                                        <LoadingSpinner
                                            active={skipFetching}
                                            usingMobile={usingMobile}
                                            large={false}
                                        ></LoadingSpinner>
                                    )}
                                </button>
                            </div>

                            {/* {gasFetching && !skipping && (
                                <LoadingSpinnerAndText
                                    loadingText="We received your utility credentials and are processing your data"
                                    active={gasFetching}
                                    usingMobile={usingMobile}
                                ></LoadingSpinnerAndText>
                            )} */}
                        </div>
                    )}

                    {usingMobile && skipping && (
                        <>
                            <div
                                style={{
                                    height: skipping ? '100vh' : '0vh',
                                    width: '100vw',
                                    zIndex: skipping ? '998' : '',
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    backgroundColor: '#00000033',
                                    backdropFilter: 'blur(2px)',
                                }}
                            ></div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    position: 'absolute',
                                    height: skipping ? '65vh' : '',

                                    backgroundColor: skipping ? '#FFFFFF' : '',
                                    zIndex: skipping ? '999' : '',
                                    bottom: skipping ? '0' : '',
                                    left: skipping ? '0' : '',
                                    borderRadius: skipping ? '20px 20px 0px 0px' : '',
                                }}
                            >
                                <div
                                    style={{
                                        width: '90%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                        height: '9%',
                                    }}
                                >
                                    <img
                                        src="/close.svg"
                                        alt=""
                                        width={'32px'}
                                        height={'32px'}
                                        onClick={() => {
                                            setHasPropane(false);
                                            setNoGas(false);
                                            setSkipping(false);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    ></img>
                                </div>
                                <div
                                    style={{
                                        height: '85%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingLeft: usingMobile ? '1vw' : '',
                                        paddingRight: usingMobile ? '1vw' : '',
                                    }}
                                >
                                    {skipping && (
                                        <>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '27%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '80%',
                                                        fontFamily: 'Raleway',
                                                        fontSize: '1.875rem',
                                                        lineHeight: '26px',
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        color: '#F9136B',
                                                    }}
                                                >
                                                    ARE YOU SURE?
                                                </div>
                                                <div
                                                    style={{
                                                        width: '80%',
                                                        fontFamily: 'AmericanTypewriter',
                                                        fontSize: '1.125rem',
                                                        lineHeight: '26px',
                                                        fontWeight: '400',
                                                        textAlign: 'center',
                                                        color: '#343A40',
                                                    }}
                                                >
                                                    We cant be sure on your savings if you have natural gas service and
                                                    you don't connect it.
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '60%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    height: '20%',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                    }}
                                                    onClick={() => {
                                                        setNoGas(false);
                                                        setHasPropane(!hasPropane);
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: '22px',
                                                            width: '22px',
                                                            margin: '0px',
                                                            border: '1px solid #1C1C1A',
                                                            borderRadius: '50%',
                                                            backgroundColor: hasPropane ? '#1C1C1A' : '#FFFFFF',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {hasPropane && (
                                                            <div
                                                                style={{
                                                                    height: '12px',
                                                                    width: '12px',
                                                                    margin: '0px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                    <label
                                                        style={{
                                                            fontFamily: 'Raleway',
                                                            fontSize: '0.875rem',
                                                            lineHeight: '16px',
                                                            fontWeight: '500',
                                                            textAlign: 'center',
                                                            color: '#1C1C1A',
                                                            marginLeft: '12px',
                                                        }}
                                                    >
                                                        I have Propane/Heating Oil
                                                    </label>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                    }}
                                                    onClick={() => {
                                                        setHasPropane(false);
                                                        setNoGas(!noGas);
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: '22px',
                                                            width: '22px',
                                                            margin: '0px',
                                                            border: '1px solid #1C1C1A',
                                                            borderRadius: '50%',
                                                            backgroundColor: noGas ? '#1C1C1A' : '#FFFFFF',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {noGas && (
                                                            <div
                                                                style={{
                                                                    height: '12px',
                                                                    width: '12px',
                                                                    margin: '0px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                    <label
                                                        style={{
                                                            fontFamily: 'Raleway',
                                                            fontSize: '0.875rem',
                                                            lineHeight: '16px',
                                                            fontWeight: '500',
                                                            textAlign: 'center',
                                                            color: '#1C1C1A',
                                                            marginLeft: '12px',
                                                        }}
                                                    >
                                                        I don’t have gas
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {usingMobile && skipping && (
                                        <button
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontSize: '1.25rem',
                                                fontWeight: '500',
                                                lineHeight: '22px',
                                                backgroundColor: skipFetching
                                                    ? '#CED4DA'
                                                    : hasPropane || noGas
                                                    ? '#212529'
                                                    : '#CED4DA',
                                                color: '#FFFFFF',
                                                borderRadius: '4px',
                                                cursor: hasPropane || noGas ? '#pointer' : 'default',
                                                height: '7.5vh',
                                                width: '88.5vw',
                                                marginBottom: '4vh',
                                            }}
                                            onClick={() => {
                                                handleSkippingGas();
                                            }}
                                            disabled={(!hasPropane && !noGas) || skipFetching}
                                        >
                                            {!skipFetching && 'SUBMIT'}
                                            <LoadingSpinner
                                                active={skipFetching}
                                                usingMobile={usingMobile}
                                                large={false}
                                            ></LoadingSpinner>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
            {!validSession && <div>Please Log In Before Adding User Data</div>}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
    gasUtilities: state.utilities.gasUtils,
}))(GasCredsPage);

import { Button } from '@mui/material';
import * as sessionActions from '../../store/session';
import { RootState, useAppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import { fetch_wrapper } from '../../utils/fetch_wrapper';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as stepActions from '../../store/step';
import LoadingSpinner from '../LoadingSpinnerContainer/loadingSpinner';

const environment = process.env.REACT_APP_ENVIRONMENT;

const KelvinsTitleBox = ({
    usingMobile,
    agent,
    totalStatistics,
}: {
    usingMobile: boolean;
    agent: any;
    totalStatistics: any;
}) => {
    const imageSrc = useMemo(() => (agent ? agent.picture_link : undefined), [agent]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [periodCounter, setPeriodCounter] = useState<number>(0);

    useEffect(() => {
        const interval1 = setInterval(() => {
            setPeriodCounter((v) => (v === 3 ? 0 : v + 1));
        }, 250);

        return () => {
            clearInterval(interval1);
        };
    }, []);

    const handleLogout = async () => {
        dispatch(stepActions.logOutSteps());
        await fetch_wrapper(`/log-out-session`, {
            method: 'GET',
            credentials: 'include',
        });
        dispatch(sessionActions.sessionInvalidated());
        navigate('/');
    };

    const handleScrollDown = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div
            id="KelvinsTitleBox"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                backgroundColor: '#212529',
                height: '100vh',
                width: usingMobile ? '100vw' : '50vw',
                color: 'white',
            }}
        >
            {/* {environment === 'dev' && (
                <Button
                    variant="outlined"
                    style={{
                        fontSize: '1.125rem',
                        marginTop: '20px',
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: '5',
                    }}
                    onClick={handleLogout}
                >
                    LogOut
                </Button>
            )} */}
            {/* <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            > */}
            <img
                src="/wattsons_logo.svg"
                alt=""
                width={usingMobile ? '208.8px' : '348px'}
                height={usingMobile ? '147px' : '245px'}
                onClick={() => window.open('https://www.wattsons.io', '_blank')}
                style={{ cursor: 'pointer' }}
            ></img>
            {/* <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                    }}
                >
                    <LoadingSpinner active={true} usingMobile={false} large={false}></LoadingSpinner>
                    
                </div>
            </div> */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: usingMobile ? '23.5vh' : '',
                    marginTop: usingMobile ? '' : '1vh',
                }}
            >
                {agent ? (
                    <>
                        <img
                            loading="lazy"
                            src={imageSrc}
                            alt=""
                            width={usingMobile ? '95px' : '167px'}
                            height={usingMobile ? '95px' : '167px'}
                            style={{ marginTop: usingMobile ? '' : '', borderRadius: '50%' }}
                        ></img>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                marginTop: usingMobile ? '' : '2vh',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '1.5rem',
                                    fontWeight: '600',
                                    lineHeight: '28px',
                                    letterSpacing: '0.05em',
                                }}
                            >
                                {agent.name ? agent.name : 'Agent Name'}
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                    letterSpacing: '0.05em',
                                    marginTop: '0.2vh',
                                }}
                            >
                                {agent.title ? agent.title : 'Agent Title'}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                                width: usingMobile ? '16vw' : '20%',
                                marginTop: usingMobile ? '' : '2vh',
                                height: '20px',
                            }}
                        >
                            <img
                                alt=""
                                src="/linkedin-icon.svg"
                                style={{
                                    cursor: agent.linkedin_url ? 'pointer' : 'default',
                                }}
                                onClick={() => {
                                    if (agent.linkedin_url) window.open(agent.linkedin_url, '_blank');
                                }}
                            ></img>
                            <img
                                alt=""
                                style={{
                                    height: '16px',
                                    width: '20px',
                                    cursor: agent.email ? 'pointer' : 'default',
                                    marginLeft: '24px',
                                }}
                                src="/email-icon.svg"
                                onClick={() => {
                                    if (agent.email) window.open(`mailto:${agent.email}`, '_blank');
                                }}
                            ></img>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                width: usingMobile ? '95px' : '167px',
                                height: usingMobile ? '95px' : '167px',
                                backgroundColor: '#6C757D',
                                borderRadius: '50%',
                            }}
                            className="loading-box-shine"
                        ></div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                marginTop: usingMobile ? '' : 'calc(2vh + 2px)',
                            }}
                        >
                            <div
                                style={{
                                    height: 'calc(1.5rem - 3px)',
                                    backgroundColor: '#6C757D',
                                    width: '167px',
                                    marginTop: '2px',
                                }}
                                className="loading-box-shine"
                            ></div>
                            <div
                                style={{
                                    height: 'calc(0.875rem - 2px)',
                                    width: '127px',
                                    marginTop: 'calc(0.2vh + 5px)',
                                    backgroundColor: '#6C757D',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    zIndex: '1',
                                    marginBottom: '2px',
                                }}
                                className="loading-box-shine"
                            ></div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                                marginTop: usingMobile ? '' : 'calc(2vh + 2px)',
                                height: '18px',
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: '#6C757D',
                                    width: '64px',
                                    height: '18px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    zIndex: '1',
                                }}
                                className="loading-box-shine"
                            >
                                <div
                                    style={{
                                        width: '24px',
                                        height: '18px',
                                        backgroundColor: '#212529',
                                        zIndex: '2',
                                    }}
                                ></div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {agent ? (
                <>
                    <div
                        style={{
                            marginBottom: '2vh',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#373A3E',
                                height: usingMobile ? '9vh' : '8vh',
                                width: usingMobile ? '75vw' : '22vw',
                                borderRadius: '2px',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                    letterSpacing: '0.05em',
                                }}
                            >
                                Clients {agent ? agent.name?.split(' ')[0] : ''} helped
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '1.375rem',
                                    fontWeight: '600',
                                    lineHeight: '28px',
                                    letterSpacing: '0.05em',
                                    marginTop: '0.6vh',
                                }}
                            >
                                {agent
                                    ? agent.customers_onboarded
                                    : totalStatistics
                                    ? totalStatistics.customers_onboarded
                                    : 0}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#373A3E',
                                height: usingMobile ? '9vh' : '8vh',
                                width: usingMobile ? '75vw' : '22vw',
                                borderRadius: '2px',
                                marginTop: usingMobile ? '2vh' : '1.5vh',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                    letterSpacing: '0.05em',
                                }}
                            >
                                Money saved
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '1.375rem',
                                    fontWeight: '600',
                                    lineHeight: '28px',
                                    letterSpacing: '0.05em',
                                    marginTop: '0.6vh',
                                }}
                            >
                                $
                                {agent
                                    ? parseInt(agent.customers_money_saved).toLocaleString()
                                    : totalStatistics
                                    ? parseInt(totalStatistics.customers_money_saved).toLocaleString()
                                    : '0'}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        style={{
                            marginBottom: '2vh',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#373A3E',
                                height: usingMobile ? '9vh' : '8vh',
                                width: usingMobile ? '75vw' : '22vw',
                                borderRadius: '2px',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                    letterSpacing: '0.05em',
                                }}
                            >
                                Clients helped
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '1.375rem',
                                    fontWeight: '600',
                                    lineHeight: '28px',
                                    letterSpacing: '0.05em',
                                    marginTop: '0.6vh',
                                }}
                            >
                                <span style={{ visibility: periodCounter > 0 ? 'visible' : 'hidden' }}>.</span>
                                <span
                                    style={{ visibility: periodCounter > 1 ? 'visible' : 'hidden', marginLeft: '2px' }}
                                >
                                    .
                                </span>
                                <span
                                    style={{ visibility: periodCounter > 2 ? 'visible' : 'hidden', marginLeft: '2px' }}
                                >
                                    .
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#373A3E',
                                height: usingMobile ? '9vh' : '8vh',
                                width: usingMobile ? '75vw' : '22vw',
                                borderRadius: '2px',
                                marginTop: usingMobile ? '2vh' : '1.5vh',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                    letterSpacing: '0.05em',
                                }}
                            >
                                Money saved
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '1.375rem',
                                    fontWeight: '600',
                                    lineHeight: '28px',
                                    letterSpacing: '0.05em',
                                    marginTop: '0.6vh',
                                }}
                            >
                                <span style={{ visibility: periodCounter > 0 ? 'visible' : 'hidden' }}>.</span>
                                <span
                                    style={{ visibility: periodCounter > 1 ? 'visible' : 'hidden', marginLeft: '2px' }}
                                >
                                    .
                                </span>
                                <span
                                    style={{ visibility: periodCounter > 2 ? 'visible' : 'hidden', marginLeft: '2px' }}
                                >
                                    .
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {usingMobile && (
                <button
                    style={{
                        fontSize: '1.25rem',
                        fontFamily: 'Raleway',
                        backgroundColor: '#FFFFFF',
                        color: '#212529',
                        border: '1px solid',
                        borderColor: '#212529',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        width: '55%',
                        height: '7vh',
                        fontWeight: '600',
                        marginBottom: '1vh',
                    }}
                    onClick={handleScrollDown}
                >
                    GET STARTED
                </button>
            )}
        </div>
    );
};

export default connect((state: RootState) => ({
    agent: state.titleData.agent,
    totalStatistics: state.titleData.totalStatistics,
}))(KelvinsTitleBox);

import React, { ReactNode } from 'react';
import StepTracker from '../StepTracker';
import { RootState } from '../../store';
import { connect } from 'react-redux';

const ContentWrapper = ({
    children,
    usingMobile,
    step,
}: {
    children: ReactNode;
    usingMobile: boolean;
    step: number | null;
}) => {
    return (
        <>
            <div
                id="ContentWrapper"
                style={{
                    height: usingMobile ? '100vh' : '100%',
                    width: usingMobile ? '90vw' : window.innerWidth >= 1920 ? window.innerWidth >= 2100 ?  window.innerWidth >= 2460 ? '20vw' : '24vw' : '27vw' : '35.5vw',
                    display: 'flex',
                    paddingLeft: usingMobile ? '0vw' : window.innerWidth >= 1920 ? window.innerWidth >= 2100 ?  window.innerWidth >= 2460 ? '15vw' : '13vw' : '11.5vw' : '7.25vw',
                    paddingRight: usingMobile ? '0vw' : window.innerWidth >= 1920 ? window.innerWidth >= 2100 ?  window.innerWidth >= 2460 ? '15vw' : '13vw' : '11.5vw' : '7.25vw',
                    flexDirection: 'column',
                }}
            >
                <div style={{ height: !!step ? '11vh' : usingMobile ? '5vh' : '7vh' }}>
                    {!!step && <StepTracker usingMobile={usingMobile}></StepTracker>}
                </div>
                <div
                    style={{
                        height: usingMobile ? (!!step ? '87vh' : '95vh') : '87.5vh',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: usingMobile ? (!!step ? '2vh' : '') : '1.5vh',
                    }}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

export default connect((state: RootState) => ({ step: state.step.maxStep }))(ContentWrapper);

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';

const OAuthRedirectPage = () => {
    const location = useLocation();
    const { utility_slug } = useParams();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const error = params.get('error');

        console.log(utility_slug);

        if (code) {
            console.log('Authorization code:', code);
            // Proceed to exchange the authorization code for an access token
        }

        if (error) {
            console.error('Error during OAuth process:', error);
            // Handle the error (e.g., display a message to the user)
        }
    }, [location.search]);

    const [usingMobile, setUsingMobile] = useState<boolean | null>(null);

    const windowWidth = useRef(window.innerWidth);

    useEffect(() => {
        if (windowWidth.current < 850) {
            setUsingMobile(true);
        } else {
            setUsingMobile(false);
        }
    }, [windowWidth]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: usingMobile ? 'column' : 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: usingMobile ? 'auto' : '90%',
            }}
        >
            <>
                <></>
                {/* <div
                style={{
                    width: usingMobile ? '100%' : '40%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: usingMobile ? '50px' : '0px',
                }}
            >
                <div>Test</div>
            </div> */}
            </>
        </div>
    );
};

export default OAuthRedirectPage;

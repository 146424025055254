const SET_NAME = 'userFormData/setName';
const SET_ADDRESS = 'userFormData/setAddress';
const SET_ELE_UTILITY_ID = 'userFormData/setEleUtilityId';
const SET_GAS_UTILITY_ID = 'userFormData/setGasUtilityId';

const setNameAction = (name: string | null) => {
    return {
        type: SET_NAME,
        payload: name,
    };
};

const setAddressAction = (address: string | null | undefined) => {
    return {
        type: SET_ADDRESS,
        payload: address,
    };
};

const setEleUtilIdAction = (eleUtilityId: string | null) => {
    return {
        type: SET_ELE_UTILITY_ID,
        payload: eleUtilityId,
    };
};

const setGasUtilIdAction = (gasUtilityId: string | null) => {
    return {
        type: SET_GAS_UTILITY_ID,
        payload: gasUtilityId,
    };
};

export const setName = (name: string | null) => (dispatch: any) => {
    dispatch(setNameAction(name));
    return;
};

export const setAddress = (address: string | null | undefined) => (dispatch: any) => {
    dispatch(setAddressAction(address));
    return;
};

export const setEleUtilId = (eleUtilityId: string | null) => (dispatch: any) => {
    dispatch(setEleUtilIdAction(eleUtilityId));
    return;
};

export const setGasUtilId = (gasUtilityId: string | null) => (dispatch: any) => {
    dispatch(setGasUtilIdAction(gasUtilityId));
    return;
};

const initialState: {
    name: string | null;
    address: string | null | undefined;
    eleUtilityId: string | null;
    gasUtilityId: string | null;
} = {
    name: null,
    address: null,
    eleUtilityId: null,
    gasUtilityId: null,
};

const userFormDataReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_NAME:
            newState = { ...state };
            newState.name = action.payload;
            return newState;
        case SET_ADDRESS:
            newState = { ...state };
            newState.address = action.payload;
            return newState;
        case SET_ELE_UTILITY_ID:
            newState = { ...state };
            newState.eleUtilityId = action.payload;
            return newState;
        case SET_GAS_UTILITY_ID:
            newState = { ...state };
            newState.gasUtilityId = action.payload;
            return newState;
        default:
            return state;
    }
};

export default userFormDataReducer;

import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import * as sessionActions from './store/session';
import * as titleDataActions from './store/titleData';
import * as mobileActions from './store/mobile';
import * as utilityActions from './store/utilities';
import { useAppDispatch } from './store/index';
import HomePage from './pages/HomePage';
import OAuthRedirectPage from './pages/OAuthRedirectPage';
import InfoFormPage from './pages/InfoFormPage';
import ElectricCredsPage from './pages/ElectricCredsPage';
import GasCredsPage from './pages/GasCredsPage';
import ConfirmInfoPage from './pages/ConfirmInfoPage';
import PageWrapper from './components/PageWrapper';
import CalculatingPage from './pages/CalculatingPage';
import TestingPage from './pages/TestingPage';

function App() {
    const [isLoaded, setIsLoaded] = useState(true);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const windowWidth = useRef(window.innerWidth);
    const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    useEffect(() => {
        dispatch(sessionActions.validateSession());
    }, []);

    useEffect(() => {
        dispatch(utilityActions.getEleUtils());
        dispatch(utilityActions.getGasUtils());
    }, []);

    useEffect(() => {
        const searchParams = Object.fromEntries(new URLSearchParams(location.search));
        if (searchParams['agent']) {
            dispatch(titleDataActions.getAgentData(searchParams['agent']));
        } else {
            // dispatch(titleDataActions.getTotalStatistics());
            dispatch(titleDataActions.getAgentData(null));
        }
    }, []);

    useEffect(() => {
        if (windowWidth.current < 850) {
            dispatch(mobileActions.setUsingMobile(true));
        }
    }, []);

    return (
        <>
            {isLoaded && (
                <div className="App">
                    <PageWrapper>
                        <Routes>
                            <Route path="/" element={<HomePage />}></Route>
                            <Route path="/infoform" element={<InfoFormPage />}></Route>
                            <Route path="/electric" element={<ElectricCredsPage />}></Route>
                            <Route path="/gas" element={<GasCredsPage />}></Route>
                            <Route path="/confirm" element={<ConfirmInfoPage />}></Route>
                            <Route path="/calculating" element={<CalculatingPage />}></Route>
                            {/* <Route path="/userdata" element={<UserDataVisualization />}></Route> */}
                            <Route path="/oauthredirect/:utility_slug" element={<OAuthRedirectPage />}></Route>


                            {REACT_APP_ENVIRONMENT === "dev" && <Route path="/test" element={<TestingPage />}></Route>}
                        </Routes>
                    </PageWrapper>
                </div>
            )}
        </>
    );
}

export default App;

const environment = process.env.REACT_APP_ENVIRONMENT;
const io_backend_url = process.env.REACT_APP_IO_BACKEND_URL;
const ca_backend_url = process.env.REACT_APP_CA_BACKEND_URL;
const default_country_code = process.env.REACT_APP_DEFAULT_COUNTRY_CODE;

export const fetch_wrapper = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    let backend_url = '';

    const hostname = window.location.hostname;

    if (hostname.endsWith('secure.wattsons.io')) {
        backend_url = io_backend_url!;
    } else if (hostname.endsWith('secure.wattsons.ca')) {
        backend_url = ca_backend_url!;
    } else if (environment === 'dev') {
        backend_url = ca_backend_url!;
    }

    const url: RequestInfo = backend_url! + input;
    init = init || {};

    if (environment === 'dev') {
        init.headers = {
            ...(init.headers || {}),
            'Requesting-Domain-Country-Code-Key': default_country_code!,
        };
    }

    return fetch(url, init);
};

import { TextField } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import { fetch_wrapper } from '../../utils/fetch_wrapper';
import * as stepActions from '../../store/step';
import LoadingBox from '../../components/LoadingBox';
import { force_minimum_load } from '../../utils/minimum_loading';

const ConfirmInfoPage = ({ validSession, usingMobile }: { validSession: boolean; usingMobile: boolean }) => {
    const [customerName, setCustomerName] = useState('No Name Data');
    const [customerAddress, setCustomerAddress] = useState('No Address Data');
    const [eleUtilityNames, setEleUtilityNames] = useState(['No Electricity Data']);
    const [gasUtilityNames, setGasUtilityNames] = useState(['No Gas Data']);
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(stepActions.setMaxStep(4));
        dispatch(stepActions.setCurrentStep(4));
    }, []);

    useEffect(() => {
        setLoading(true);
        const waitPromise = force_minimum_load(2700, 2800);
        fetch_wrapper(`/get-user-confirm-data`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then(async (data) => {
                setCustomerName(data.name);
                setCustomerAddress(data.address);
                setEleUtilityNames(data.ele_utility_full_names);
                setGasUtilityNames(data.gas_utility_full_names);
                await waitPromise;
                setLoading(false);
            })
            .catch((error) => console.log('error', error));
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                navigate('/calculating');
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            {loading && <LoadingBox usingMobile={usingMobile}></LoadingBox>}
            {!loading && validSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: usingMobile ? '4vh' : '',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '1.875rem',
                                lineHeight: '40px',
                            }}
                        >
                            YOUR INFORMATION
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: usingMobile ? 'space-between' : 'flex-start',
                            width: '100%',
                            height: usingMobile ? '76.5vh' : '84vh',
                            marginTop: usingMobile ? '6vh' : '5vh',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                height: usingMobile ? '65vh' : '',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontWeight: '600',
                                    fontSize: '1.25rem',
                                    height: usingMobile ? '2vh' : '',
                                }}
                            >
                                Address information
                            </div>

                            <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: usingMobile ? '5.5vh' : '',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '1rem',
                                        lineHeight: '22px',
                                    }}
                                >
                                    {customerName}
                                </div>
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontWeight: '400',
                                        fontSize: '1rem',
                                        width: usingMobile ? '' : '13vw',
                                        lineHeight: '22px',
                                    }}
                                >
                                    {customerAddress}
                                </div>
                            </div>

                            <div style={{ height: '4vh' }}></div>

                            <div>
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    Electricity Utility
                                </div>

                                {eleUtilityNames.map((ele_utility_name, key) => {
                                    if (key < 4) {
                                        return (
                                            <div key={'containerDiv' + ele_utility_name + key}>
                                                <div
                                                    key={'spacingDiv' + ele_utility_name + key}
                                                    style={{ height: '2vh' }}
                                                ></div>
                                                <div
                                                    style={{
                                                        fontFamily: 'Raleway',
                                                        fontWeight: '400',
                                                        fontSize: '1rem',
                                                        width: usingMobile ? '' : '13vw',
                                                        lineHeight: '22px',
                                                    }}
                                                    key={ele_utility_name + key}
                                                >
                                                    {ele_utility_name}
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>

                            <div style={{ height: '4vh' }}></div>

                            <div>
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    Gas Utility
                                </div>

                                {gasUtilityNames.map((gas_utility_name, key) => {
                                    if (key < 4) {
                                        return (
                                            <div key={'containerDiv' + gas_utility_name + key}>
                                                <div
                                                    key={'spacingDiv' + gas_utility_name + key}
                                                    style={{ height: '2vh' }}
                                                ></div>
                                                <div
                                                    style={{
                                                        fontFamily: 'Raleway',
                                                        fontWeight: '400',
                                                        fontSize: '1rem',
                                                        width: usingMobile ? '' : '13vw',
                                                        lineHeight: '22px',
                                                    }}
                                                    key={gas_utility_name + key}
                                                >
                                                    {gas_utility_name}
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        {!usingMobile && <div style={{ height: '4vh' }}></div>}

                        <button
                            style={{
                                fontFamily: 'Raleway',
                                fontSize: '1.25rem',
                                fontWeight: '500',
                                lineHeight: '22px',
                                backgroundColor: '#000000',
                                color: '#FFFFFF',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                height: usingMobile ? '7.5vh' : '8vh',
                                width: usingMobile ? '98%' : '35%',
                                marginBottom: usingMobile ? '4vh' : '',
                                maxHeight: usingMobile ? '' : '50px',
                                maxWidth: usingMobile ? '' : '130px',
                            }}
                            onClick={() => navigate('/calculating')}
                        >
                            CONFIRM
                        </button>
                    </div>
                </>
            )}
            {!loading && !validSession && <div>Please Log In Before Adding User Data</div>}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
}))(ConfirmInfoPage);

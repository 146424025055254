import React, { useEffect, useRef, useState, ReactNode } from 'react';
import KelvinsTitleBox from '../KelvinsTitleBox';
import ContentWrapper from '../ContentWrapper';
import { RootState } from '../../store';
import { connect } from 'react-redux';

const PageWrapper = ({ children, usingMobile }: { children: ReactNode; usingMobile: boolean }) => {
    return (
        <div
            id="PageWrapper"
            style={{
                display: 'flex',
                flexDirection: usingMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: usingMobile ? 'auto' : '100vh',
                width: '100vw',
                position: usingMobile ? 'relative' : 'unset',
            }}
        >
            <KelvinsTitleBox usingMobile={usingMobile}></KelvinsTitleBox>
            <ContentWrapper usingMobile={usingMobile}>{children}</ContentWrapper>
        </div>
    );
};

export default connect((state: RootState) => ({ usingMobile: state.mobile.usingMobile }))(PageWrapper);

import { fetch_wrapper } from '../utils/fetch_wrapper';

const SET_VALID = 'session/setValid';
const SET_INVALID = 'session/setInvalid';

const sessionValid = () => {
    return {
        type: SET_VALID,
        payload: true,
    };
};

const sessionInvalid = () => {
    return {
        type: SET_INVALID,
        payload: false,
    };
};

export const sessionValidated = () => (dispatch: any) => {
    dispatch(sessionValid());
    return;
};

export const sessionInvalidated = () => (dispatch: any) => {
    dispatch(sessionInvalid());
    return;
};

export const validateSession = () => async (dispatch: any) => {
    await fetch_wrapper(`/validate-session`, {
        method: 'GET',
        credentials: 'include',
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.session_valid) {
                dispatch(sessionValid());
            } else {
                dispatch(sessionInvalid());
            }
        });
    return;
};

const initialState = { validSession: false };

const sessionReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_VALID:
            newState = { ...state };
            newState.validSession = action.payload;
            return newState;
        case SET_INVALID:
            newState = { ...state };
            newState.validSession = action.payload;
            return newState;
        default:
            return state;
    }
};

export default sessionReducer;

import { FormEvent, useEffect, useState } from 'react';
import VerificationInput from './VerificationInput';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import * as sessionActions from '../../store/session';
import { fetch_wrapper } from '../../utils/fetch_wrapper';
import * as stepActions from '../../store/step';
import LoadingSpinner from '../LoadingSpinnerContainer/loadingSpinner';
import { force_minimum_load } from '../../utils/minimum_loading';

type props = {
    secondsLeft: number;
    phoneNumber: string;
    sendVerifText: (waitPromise: Promise<unknown> | null) => void;
    setSecondsLeft: React.Dispatch<React.SetStateAction<number>>;
    resendSeconds: number;
    usingMobile: boolean;
};

const VerificationForm = ({
    secondsLeft,
    phoneNumber,
    sendVerifText,
    setSecondsLeft,
    resendSeconds,
    usingMobile,
}: props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [verificationCode, setVerificationCode] = useState('');
    const [verifSuccess, setVerifSuccess] = useState<boolean | null>(null);
    const [resetAfterFailure, setResetAfterFailure] = useState<boolean>(false);
    const [verificationResultFetching, setVerificationResultFetching] = useState<boolean>(false);

    const handleVerificationSubmit = () => {
        setResetAfterFailure(false);
        setVerifSuccess(null);
        setVerificationResultFetching(true);
        const waitPromise = force_minimum_load();
        if (verificationCode) {
            fetch_wrapper(`/text-verification-check`, {
                method: 'POST',
                body: JSON.stringify({ phone_number: phoneNumber, verification_code: verificationCode }),
                credentials: 'include',
            })
                .then((response) => response.json())
                .then(async (data) => {
                    await waitPromise;
                    if (data.status === 'success') {
                        setVerifSuccess(true);
                        dispatch(sessionActions.sessionValidated());
                        dispatch(stepActions.setMaxStep(1));
                        navigate(`/infoform`);
                    } else {
                        setVerificationResultFetching(false);
                        setVerifSuccess(false);
                        setVerificationCode('');
                    }
                });
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && verificationCode.toString().length === 5 && !verificationResultFetching) {
                handleVerificationSubmit();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [verificationCode, verificationResultFetching]);

    const resendVerifCode = () => {
        setVerificationCode('');
        setSecondsLeft(resendSeconds);
        sendVerifText(null);
    };

    return (
        <form
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: usingMobile ? 'space-between' : 'flex-start',
                alignItems: usingMobile ? 'center' : 'flex-start',
                width: usingMobile ? '100%' : '75%',
                height: usingMobile ? '54.5vh' : '',
                marginTop: usingMobile ? '6vh' : '5vh',
                marginBottom: '0px',
            }}
            onSubmit={(e) => {
                e.preventDefault();
                handleVerificationSubmit();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        fontFamily: 'Raleway',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                        marginBottom: '1vh',
                        color: '#495057',
                    }}
                >
                    Verification Code
                </div>

                <VerificationInput
                    verificationCode={verificationCode}
                    setVerificationCode={setVerificationCode}
                    verifSuccess={verifSuccess}
                    resetAfterFailure={resetAfterFailure}
                    setResetAfterFailure={setResetAfterFailure}
                    usingMobile={usingMobile}
                />

                {usingMobile && (
                    <div
                        style={{
                            color: 'red',
                            fontFamily: 'Raleway',
                            fontSize: '0.875rem',
                            width: '100%',
                            height: '2vh',
                            fontWeight: '400',
                            marginTop: '3vh',
                        }}
                    >
                        {!verifSuccess && verifSuccess !== null && 'The code provided is incorrect, please try again'}
                    </div>
                )}
            </div>

            {!usingMobile && <div style={{ height: '3vh' }}></div>}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: usingMobile ? '4vh' : '',
                }}
            >
                <div
                    style={{
                        fontSize: '0.75rem',
                        lineHeight: '14px',
                        fontFamily: 'Raleway',
                        display: 'flex',
                        justifyContent: usingMobile ? 'center' : 'flex-start',
                        alignItems: 'center',
                        visibility: secondsLeft ? 'visible' : 'hidden',
                        width: usingMobile ? '52%' : '100%',
                        whiteSpace: 'pre-wrap',
                        height: usingMobile ? '4vh' : '2vh',
                    }}
                >
                    Please wait {secondsLeft} seconds before resending verification code
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: usingMobile ? 'space-between' : 'flex-start',
                        marginTop: usingMobile ? '2.5vh' : '1vh',
                    }}
                >
                    <button
                        disabled={!!secondsLeft}
                        onClick={resendVerifCode}
                        className="button-width-short"
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '1.25rem',
                            lineHeight: '22px',
                            fontWeight: '500',
                            backgroundColor: '#FFFFFF',
                            color: secondsLeft ? '#909294' : '#212529',
                            border: '1px solid',
                            borderColor: secondsLeft ? '#909294' : '#212529',
                            borderRadius: '4px',
                            cursor: secondsLeft ? 'default' : 'pointer',
                            width: usingMobile ? '47.5%' : '56%',
                            height: usingMobile ? '7.5vh' : '8vh',
                            maxHeight: usingMobile ? '' : '50px',
                            maxWidth: usingMobile ? '' : '200px',
                        }}
                    >
                        RESEND CODE
                    </button>

                    <button
                        type="submit"
                        disabled={!(verificationCode.toString().length === 5) || verificationResultFetching}
                        className="button-width-short"
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '1.25rem',
                            lineHeight: '22px',
                            fontWeight: '500',
                            backgroundColor:
                                verificationCode.toString().length === 5
                                    ? verificationResultFetching
                                        ? '#CED4DA'
                                        : '#212529'
                                    : '#CED4DA',
                            color: '#FFFFFF',
                            borderRadius: '4px',
                            cursor: verificationCode.toString().length === 5 ? verificationResultFetching ? 'wait' : 'pointer' : 'default',
                            width: usingMobile ? '47.5%' : '40%',
                            height: usingMobile ? '7.5vh' : '8vh',
                            maxHeight: usingMobile ? '' : '50px',
                            maxWidth: usingMobile ? '' : '130px',
                            marginLeft: '28px',
                        }}
                    >
                        {verificationResultFetching ? '' : 'SUBMIT'}

                        <LoadingSpinner active={verificationResultFetching} usingMobile={usingMobile} large={false}></LoadingSpinner>
                    </button>
                </div>
            </div>

            {!usingMobile && (
                <div
                    style={{
                        color: 'red',
                        fontFamily: 'Raleway',
                        fontSize: '1rem',
                        width: '100%',
                        height: '1.5vh',
                        fontWeight: '400',
                        marginTop: '2vh',
                    }}
                >
                    {!verifSuccess && verifSuccess !== null && 'The code provided is incorrect, please try again'}
                </div>
            )}
        </form>
    );
};

export default VerificationForm;

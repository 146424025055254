const SET_MAX_STEP = 'step/setMaxStep';
const SET_CURRENT_STEP = 'step/setCurrentStep';
const SET_LOGOUT_STEPS = 'step/setLogoutSteps';

const setMaxStepAction = (step: number | null) => {
    return {
        type: SET_MAX_STEP,
        payload: step,
    };
};

const setCurrentStepAction = (step: number | null) => {
    return {
        type: SET_CURRENT_STEP,
        payload: step,
    };
};

const setLogOutSteps = () => {
    return {
        type: SET_LOGOUT_STEPS,
    };
};

export const setMaxStep = (step: number | null) => (dispatch: any) => {
    dispatch(setMaxStepAction(step));
    return;
};

export const setCurrentStep = (step: number | null) => (dispatch: any) => {
    dispatch(setCurrentStepAction(step));
    return;
};

export const logOutSteps = () => (dispatch: any) => {
    dispatch(setLogOutSteps());
    return;
};

const initialState = { maxStep: 0, currentStep: 0 };

const stepReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_MAX_STEP:
            newState = { ...state };
            newState.maxStep = action.payload > newState.maxStep ? action.payload : newState.maxStep;
            return newState;
        case SET_CURRENT_STEP:
            newState = { ...state };
            newState.currentStep = action.payload;
            return newState;
        case SET_LOGOUT_STEPS:
            newState = { ...state };
            newState.currentStep = 0;
            newState.maxStep = 0;
            return newState;
        default:
            return state;
    }
};

export default stepReducer;

import { useEffect, useRef, useState } from 'react';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type props = {
    maxStep: number;
    currentStep: number;
    usingMobile: boolean;
};

const numberObj: { [key: number]: string } = {
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four',
    5: 'Four',
};

const StepTracker = ({ maxStep, currentStep, usingMobile }: props) => {
    const [highlightOne, setHighlightOne] = useState(false);
    const [highlightTwo, setHighlightTwo] = useState(false);
    const [highlightThree, setHighlightThree] = useState(false);
    const [highlightFour, setHighlightFour] = useState(false);
    const [shortenText, setShortenText] = useState(false);
    const windowWidth = useRef(window.innerWidth);

    const highlightDelay = 150;

    const navigate = useNavigate();

    useEffect(() => {
        if (windowWidth.current <= 1230) {
            setShortenText(true);
        }
    }, []);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            width: '24%',
                            cursor: highlightOne ? 'pointer' : 'default',
                            paddingTop: usingMobile ? '3vh' : '2vh',
                        }}
                        onMouseEnter={() => {
                            if (maxStep >= 1) setHighlightOne(true);
                        }}
                        onMouseLeave={() => {
                            if (maxStep >= 1) setTimeout(() => setHighlightOne(false), highlightDelay);
                        }}
                        onClick={() => {
                            if (maxStep >= 1) {
                                navigate('/infoform');
                            }
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '0.75rem',
                                lineHeight: '14px',
                                color: currentStep === 1 ? '#212529' : maxStep >= 1 ? '#6C757D' : '#ADB5BD',
                                marginLeft: '1px',
                            }}
                        >
                            Login
                        </div>
                        <div
                            style={{
                                height: '0.5vh',
                                width: '100%',
                                backgroundColor: currentStep === 1 ? '#212529' : maxStep >= 1 ? '#6C757D' : '#E9ECEF',
                                borderRadius: '2px',
                                marginTop: '0.5vh',
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            width: '24%',
                            cursor: highlightTwo ? 'pointer' : 'default',
                            paddingTop: usingMobile ? '3vh' : '2vh',
                        }}
                        onMouseEnter={() => {
                            if (maxStep >= 2) setHighlightTwo(true);
                        }}
                        onMouseLeave={() => {
                            if (maxStep >= 2) setTimeout(() => setHighlightTwo(false), highlightDelay);
                        }}
                        onClick={() => {
                            if (maxStep >= 2) {
                                navigate('/electric');
                            }
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '0.75rem',
                                lineHeight: '14px',
                                color: currentStep === 2 ? '#212529' : maxStep >= 2 ? '#6C757D' : '#ADB5BD',
                                marginLeft: '1px',
                            }}
                        >
                            {!shortenText && 'Connect'} Electricity
                        </div>
                        <div
                            style={{
                                height: '0.5vh',
                                width: '100%',
                                backgroundColor: currentStep === 2 ? '#212529' : maxStep >= 2 ? '#6C757D' : '#E9ECEF',
                                borderRadius: '2px',
                                marginTop: '0.5vh',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            width: '24%',
                            cursor: highlightThree ? 'pointer' : 'default',
                            paddingTop: usingMobile ? '3vh' : '2vh',
                        }}
                        onMouseEnter={() => {
                            if (maxStep >= 3) setHighlightThree(true);
                        }}
                        onMouseLeave={() => {
                            if (maxStep >= 3) setTimeout(() => setHighlightThree(false), highlightDelay);
                        }}
                        onClick={() => {
                            if (maxStep >= 3) {
                                navigate('/gas');
                            }
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '0.75rem',
                                lineHeight: '14px',
                                color: currentStep === 3 ? '#212529' : maxStep >= 3 ? '#6C757D' : '#ADB5BD',
                                marginLeft: '1px',
                            }}
                        >
                            {!shortenText && 'Connect'} Gas
                        </div>
                        <div
                            style={{
                                height: '0.5vh',
                                width: '100%',
                                backgroundColor: currentStep === 3 ? '#212529' : maxStep >= 3 ? '#6C757D' : '#E9ECEF',
                                borderRadius: '2px',
                                marginTop: '0.5vh',
                            }}
                        />
                    </div>
                    {currentStep < 5 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                width: '24%',
                                cursor: highlightFour ? 'pointer' : 'default',
                                paddingTop: usingMobile ? '3vh' : '2vh',
                            }}
                            onMouseEnter={() => {
                                if (maxStep >= 4) setHighlightFour(true);
                            }}
                            onMouseLeave={() => {
                                if (maxStep >= 4) setTimeout(() => setHighlightFour(false), highlightDelay);
                            }}
                            onClick={() => {
                                if (maxStep >= 4) {
                                    navigate('/confirm');
                                }
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontWeight: '600',
                                    fontSize: '0.75rem',
                                    lineHeight: '14px',
                                    color: currentStep === 4 ? '#212529' : maxStep >= 4 ? '#6C757D' : '#ADB5BD',
                                    marginLeft: '1px',
                                }}
                            >
                                {!shortenText && 'Find'} Savings
                            </div>
                            <div
                                style={{
                                    height: '0.5vh',
                                    width: '100%',
                                    backgroundColor:
                                        currentStep === 4 ? '#212529' : maxStep >= 4 ? '#6C757D' : '#E9ECEF',
                                    borderRadius: '2px',
                                    marginTop: '0.5vh',
                                }}
                            />
                        </div>
                    )}
                    {currentStep >= 5 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                width: '24%',
                                cursor: highlightFour ? 'pointer' : 'default',
                                paddingTop: usingMobile ? '3vh' : '2vh',
                            }}
                            onMouseEnter={() => {
                                if (maxStep >= 5) setHighlightFour(true);
                            }}
                            onMouseLeave={() => {
                                if (maxStep >= 5) setTimeout(() => setHighlightFour(false), highlightDelay);
                            }}
                            onClick={() => {
                                if (maxStep >= 5) {
                                    navigate('/confirm');
                                }
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontWeight: '600',
                                    fontSize: '0.75rem',
                                    lineHeight: '14px',
                                    color: currentStep === 5 ? '#212529' : '#ADB5BD',
                                    marginLeft: '1px',
                                }}
                            >
                                Calculating...
                            </div>
                            <div
                                style={{
                                    height: '0.5vh',
                                    width: '100%',
                                    backgroundColor: currentStep === 5 ? '#212529' : '#E9ECEF',
                                    borderRadius: '2px',
                                    marginTop: '0.5vh',
                                }}
                            />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        fontFamily: 'Raleway',
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                    }}
                >
                    Step {numberObj[currentStep]}
                </div>
            </div>
        </>
    );
};

export default connect((state: RootState) => ({ maxStep: state.step.maxStep, currentStep: state.step.currentStep }))(
    StepTracker,
);
